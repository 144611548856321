@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Mr+Dafoe&family=Oswald:wght@200;300;400;500;600;700&display=swap');

body {
    font-family: 'Oswald', sans-serif;
    scroll-behavior: smooth;
}

.styleLogo {
    font-family: 'Mr Dafoe', cursive;
}

.altoMenu{
    height: calc(100vh - 5rem);
}
.heightMedia{
    height: 50vh;
}
.maskImage {
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 1.0) 50%, transparent 100%);
}
.maskImageVertical{
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1.0) 50%, transparent 100%);
}
.starMask{
    clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
}

.togle-close {
    left: -100vw;
}

.togle-open {
    left: 0px;
}

.dot{
    min-width: 1rem;
    min-height: 1rem;
    height: 1rem;
    width: 1rem;
    max-height: 1rem;
    max-width: 1rem;
}